import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import theme from '../../theme'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { AlertBox, FormHelper, myStyles } from 'components/index'
import { BaseTextField } from '../textfield/StyledTextField'
import apiClient from '../../services/apiClient'
import { useMutation } from 'react-query'
import { useLogContext } from '../../contexts/logContext'
import MapAutoComplete from '../textfield/MapAutoComplete'
import BusinessIcon from '@mui/icons-material/Business'
import HomeIcon from '@mui/icons-material/Home'
import ApartmentIcon from '@mui/icons-material/Apartment'
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format'
import { value } from 'lodash/seq'

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom (props, ref) {
  const { onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="(###) ###-####" allowEmptyFormatting mask="_"
    />
  )
})

function ModalCrudClient ({ open, handleClose, isEditMode, selectedClient, roads, handleUpdateClients }) {
  const { t } = useTranslation()
  const { handleAction } = useLogContext()
  const [errors, setErrors] = useState({
    msg: false,
    errorMsg: ''
  })
  const [changeMade, setChangeMade] = useState(false)
  const [buildingTypeInt, setBuildingTypeInt] = useState(1)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [roadId, setRoadId] = useState('')
  const [numberError, setNumberError] = useState(false)
  const optionsContract = ['Activ', 'Inactif']
  
  const { control, reset, handleSubmit, setValue, watch, getValues } = useForm({
    reValidateMode: 'onBlur',
    defaultValues: {
      clientId: selectedClient?.id || '',
      clientRoadId: selectedClient?.clientRoadId || '',
      clientContract: selectedClient?.clientContractNumber || '',
      clientReference: selectedClient?.clientReference || '',
      clientAddress: selectedClient?.clientAddresse || '',
      clientTelNumber: selectedClient?.clientTel || '',
      clientCellNumber: selectedClient?.clientMobile || '',
      clientBuildingTypeId: selectedClient?.buildingTypeFK || '',
      clientEmail: selectedClient?.clientEmail || '',
      clientName: selectedClient?.clientFullName || '',
      clientCallAlert: selectedClient?.clientAlerteAppel || false,
      clientSmsAlert: selectedClient?.clientAlerteSms || false,
      clientAlerteEmail: selectedClient?.clientAlerteEmail || false,
      contractStatusId: selectedClient?.contractStatusId || 1,
      tenants: selectedClient?.tenants || [],
      setRoadId: selectedClient?.roadFK || '',
    }
  })
  
  const fieldNames = ['clientId', 'clientRoadId', 'clientContract', 'clientReference', 'clientAddress', 'clientTelNumber', 'clientCellNumber', 'clientBuildingTypeId', 'clientName', 'clientCallAlert', 'clientSmsAlert', 'clientAlerteEmail', 'clientEmail', 'contractStatusId']
  const watchFields = watch(fieldNames)
  
  const onFieldsChange = (e) => {
    setChangeMade(true)
  }
  
  const { fields: tenants, append: appendTenantsRow, remove: removeTenantsRow } = useFieldArray({
    control,
    name: 'tenants'
  })
  
  const { isLoading: isLoadingCrud, mutate: crudClients } = useMutation(
    async (selectedClient) => {
      return await apiClient.crudClients(selectedClient)
    },
    {
      onSuccess: ({ data }) => {
        isEditMode ? handleAction(`Modification du client => ${data.clientFullName}`) : handleAction(`Création du client => ${data.clientFullName}`)
        handleUpdateClients()
      },
      onError: (error) => {
        setErrors({ msg: true, errorMsg: error })
      }
    }
  )
  
  const { mutate: deleteTenant } = useMutation(
    async (index) => {
      const updatedItems = [...selectedClient.tenants]
      updatedItems.splice(index, 1)
      return await apiClient.deleteTenant(selectedClient.tenants[index].id)
    },
    {
      onSuccess: ({ data }) => {
        handleAction(`Suppression du locataire de => ${selectedClient.clientFullName}`)
      },
      onError: (error) => {
        setErrors({ msg: true })
      }
    }
  )
  const onRoadChange = (e, value) => {
    setRoadId(value.id)
    setValue('clientRoadId', value.name)
  }
  
  useEffect(() => {
    roads.map((road) => {
      road.label = road.roadName
    })
    setNumberError(false)
    setChangeMade(false)
    if (isEditMode) {
      setBuildingTypeInt(selectedClient?.buildingTypeFK)
      setRoadId(selectedClient?.roadFK)
      const roadId_ = roads.find(road => road.id === selectedClient?.roadFK)?.name
      setValue('clientContract', selectedClient?.clientContractNumber)
      setValue('clientReference', selectedClient?.clientReference)
      setValue('clientRoadId', selectedClient?.roadFK)
      setValue('clientAddress', selectedClient?.clientAddresse)
      setValue('clientTelNumber', selectedClient?.clientTel)
      setValue('clientCellNumber', selectedClient?.clientMobile)
      setValue('clientBuildingTypeId', buildingTypeInt)
      setValue('clientName', selectedClient?.clientFullName)
      setValue('clientCallAlert', selectedClient?.clientAlerteAppel)
      setValue('clientSmsAlert', selectedClient?.clientAlerteSms)
      setValue('clientEmailAlert', selectedClient?.clientAlerteEmail)
      setValue('clientEmail', selectedClient?.clientEmail)
      setValue('contractStatusId', selectedClient?.contractStatusId || 1)

      const sortedTenants = [...(selectedClient?.tenants || [])].sort((a, b) => a.identification - b.identification)
      setValue('tenants', sortedTenants)
      
    } else {
      setRoadId(null)
      setBuildingTypeInt(1)
      setValue('tenants', [])
      setValue('clientContract', '')
      setValue('clientReference', '')
      setValue('clientRoadId', '')
      setValue('clientAddress', '')
      setValue('clientTelNumber', '')
      setValue('clientCellNumber', '')
      setValue('clientBuildingTypeId', '')
      setValue('clientBuildingTypeName', '')
      setValue('clientName', '')
      setValue('clientCallAlert', false)
      setValue('clientSmsAlert', false)
      setValue('clientEmailAlert', false)
      setValue('clientEmail', '')
      setValue('clientEmailAlert', false)
      setValue('contractStatusId', 1)
    }
  }, [selectedClient, open])
  
  const addNewTenants = () => {
    appendTenantsRow({ identification: '', tenantNumber: '', email: '', hasUnsubscribe: false, tenantEmail: false, tenantSMS: false, tenantVoice: false, id: null })
    onFieldsChange()
  }
  
  const handleCloseDialog = () => {
    changeMade ? (
      Swal.fire({
        icon: 'warning',
        title: t('client.Exit'),
        text: t('client.ExitConfirm'),
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.dark,
        cancelButtonText: t('app.Cancel'),
        confirmButtonText: t('app.Yes'),
      }).then((result) => {
          if (result.value) {
            resetFormAndWindow()
          }
        }
      )
    ) : (
      resetFormAndWindow()
    )
  }
  
  const resetFormAndWindow = () => {
    reset()
    setChangeMade(false)
    setErrors({})
    handleClose()
    setNumberError(false)
  }
  
  const handleOnSubmit = (values) => {
    const roadId_ = roads.find(road => road.id === values.clientRoadId)?.id
    checkPhoneNumber(values)
    if (numberError === false) {
      crudClients({
        id: isEditMode ? selectedClient?.id : null,
        clientFullName: values.clientName,
        clientEmail: values.clientEmail,
        clientContractNumber: values.clientContract,
        clientReference: values.clientReference,
        clientAddresse: values.clientAddress,
        clientMobile: values.clientCellNumber,
        clientTel: values.clientTelNumber,
        buildingTypeFK: buildingTypeInt,
        clientAlerteAppel: values.clientCallAlert,
        clientAlerteSms: values.clientSmsAlert,
        clientAlerteEmail: values.clientEmailAlert,
        roadFK: roadId_ || selectedClient?.clientRoadId,
        tenants: values.tenants,
        contractStatusId: parseInt(values?.contractStatusId)
      })
    }
  }
  
  const checkPhoneNumber = (values) => {
    const telNumber = values.clientTelNumber
    const cellNumber = values.clientCellNumber
    
    if (value?.clientCallAlert === true && telNumber === '') {
      setNumberError(true)
    } else if (cellNumber.length > 10 || cellNumber.length < 10) setNumberError(false)
    else setNumberError(false)
    if (cellNumber === '' && values?.clientSmsAlert === true) {
      if (cellNumber === '' || cellNumber < 10) {
        document.getElementById('number-error').innerHTML
          = t('client.SMSError')
        setNumberError(true)
      }
    }
  }
  
  const changedBuildingType = (id) => {
    onFieldsChange()
    switch (id) {
      case 1:
      default:
        setBuildingTypeInt(1)
        break
      case 2:
        setBuildingTypeInt(2)
        break
      case 3:
        setBuildingTypeInt(3)
        break
    }
  }
  
  const isOptionEqualToValue = (option, value) => option.id === value.id
  
  
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleCloseDialog}
      scroll={'paper'}
      ariel-labelledby="scroll-dialog-crud"
      aria-describedby="scroll-dialog-description-crud"
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-crud">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3"
                      sx={{ fontWeight: 'bold' }}>{isEditMode ? t('client.EditClient') : t('client.AddClient')}</Typography>
          <HighlightOffIcon sx={myStyles.iconTitle} onClick={() => {
            handleCloseDialog()
          }}/>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={true}>
        {
          errors?.msg &&
          <AlertBox type={'error'} message={errors?.errorMsg ? errors?.errorMsg : t('client.ErrorAddClients')}/>
        }
        <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="contractStatusId"
                defaultValue="1"
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value="1"
                      control={<Radio/>}
                      label="Actif"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio/>}
                      label="Inactif"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="clientContract"
                rules={{
                  required: true
                }}
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    fullWidth
                    type="number"
                    variant="outlined"
                    label={t('client.Contract')}
                    error={error !== undefined}
                    sx={{ mb: 0 }}
                    helperText={error ? t(FormHelper.required[error.type]) : ''}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="clientReference"
                rules={{
                  required: true
                }}
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={t('client.Reference')}
                    sx={{ mb: 0 }}
                    error={error !== undefined}
                    helperText={error ? t(FormHelper.required[error.type]) : ''}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="clientRoadId"
                variant="outlined"
                rules={{
                  required: false
                }}
                render={({ field, fieldState: { error } }) => (
                  
                  <Autocomplete
                    value={roads.find(road => road.id === field.value) || null}
                    options={roads}
                    fullWidth
                    onChange={(e, value) => {
                      onFieldsChange()
                      onRoadChange(e, value)
                      field.onChange(value ? value.id : null)
                    }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={(params) => <BaseTextField {...params} label={t('client.Road')} />}
                  />
                
                )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="clientEmail"
                rules={{
                  required: false,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                }}
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={t('client.Email')}
                    sx={{ mb: 0 }}
                    error={error !== undefined}
                    helperText={error ? t(FormHelper.required[error.type]) : ''}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="clientName"
                rules={{
                  required: true
                }}
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label={t('client.Name')}
                    sx={{ mb: 0 }}
                    error={error !== undefined}
                    helperText={error ? t(FormHelper.required[error.type]) : ''}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="errorLabel"
                render={({ field, fieldState: { error } }) => (
                  <label id="number-error" className="rsvp required-fields" style={{
                    display: numberError ? 'block' : 'none',
                    color: 'red'
                  }}>{t('client.InvalidNumber')}</label>
                
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="clientTelNumber"
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                    sx={{ mb: 0 }}
                    label={t('client.Phone')}
                    fullWidth
                    id="formatted-mainPhone-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="clientCellNumber"
                render={({ field, fieldState: { error } }) => (
                  <BaseTextField
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      onFieldsChange()
                    }}
                    sx={{ mb: 0 }}
                    label={t('client.Cellphone')}
                    fullWidth
                    id="formatted-cellPhone-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name="clientCallAlert"
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onChange={(e) => {
                        field.onChange(e)
                        onFieldsChange()
                      }}
                      control={
                        <Checkbox checked={field.value} sx={{ pt: 0, pb: 0 }} {...field} />
                      }
                      label={t('client.PhoneAlert')}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name="clientSmsAlert"
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onChange={(e) => {
                        field.onChange(e)
                        onFieldsChange()
                      }}
                      control={
                        <Checkbox checked={field.value} sx={{ pt: 0, pb: 0 }} {...field}
                        />}
                      label={t('client.SMSAlert')}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name="clientEmailAlert"
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      onChange={(e) => {
                        field.onChange(e)
                        onFieldsChange()
                      }}
                      control={
                        <Checkbox checked={field.value} sx={{ pt: 0, pb: 0 }} {...field}
                        />}
                      label={t('client.EmailAlert')}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="clientAddress"
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field: { ref, onChange, ...field }, fieldState }) => (
                  
                  <MapAutoComplete
                    field={field}
                    fullWidth
                    onChange={(e) => {
                      onChange(e)
                      onFieldsChange()
                    }}
                    errorField={!!fieldState.error}
                    helperText={fieldState.error ? t(FormHelper.required[fieldState.error.type]) : ''}
                  
                  />
                
                )}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  justifyContent="center">
              <Controller
                control={control}
                name="clientResidentialBuilding"
                render={({ field: { value, onChange, ...field } }) => (
                  <FormGroup>
                    <Typography variant="h5" sx={{ marginBottom: 0 }}>Résidentiel</Typography>
                    <HomeIcon id="homeIcon" fontSize="large" onClick={() => changedBuildingType(1)}
                              sx={buildingTypeInt === 1 ? myStyles.selected : myStyles.notSelected}/>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name="clientCommercialBuilding"
                render={({ field: { value, onChange, ...field } }) => (
                  <FormGroup>
                    <Typography variant="h5" sx={{ marginBottom: 0 }}>Commercial</Typography>
                    <BusinessIcon id="businessIcon" fontSize="large" onClick={() => changedBuildingType(2)}
                                  sx={buildingTypeInt === 2 ? myStyles.selected : myStyles.notSelected}/>
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Controller
                control={control}
                name="clientApartmentBuilding"
                render={({ field: { value, onChange, ...field } }) => (
                  <FormGroup>
                    <Typography variant="h5" sx={{ marginBottom: 0 }}>Appartement</Typography>
                    <ApartmentIcon id="apartmentIcon" fontSize="large" onClick={() => changedBuildingType(3)}
                                   sx={buildingTypeInt === 3 ? myStyles.selected : myStyles.notSelected}/>
                  </FormGroup>
                
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={addNewTenants}>
                {t('client.AddPhoneNumber')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {tenants.map((field, index) => (
                <Grid container key={field.id} spacing={1} alignItems="center" className="tenant-separator">
                  <Grid item xs={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={`tenants.${index}.identification`}
                          render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                              {...field}
                              fullWidth
                              variant="outlined"
                              label={t('client.Identification')}
                              sx={{ mb: 0 }}
                              onChange={(e) => {
                                field.onChange(e)
                                onFieldsChange()
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`tenants.${index}.tenantNumber`}
                          render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                              {...field}
                              onChange={(e) => {
                                field.onChange(e)
                                onFieldsChange()
                              }}
                              sx={{ mb: 0 }}
                              label={t('client.Phone')}
                              fullWidth
                              id={`tenants.${index}.tenantNumber`}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`tenants.${index}.email`}
                          rules={{
                            required: false,
                            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                              {...field}
                              id={`tenants.${index}.email`}
                              onChange={(e) => {
                                field.onChange(e)
                                onFieldsChange()
                              }}
                              fullWidth
                              variant="outlined"
                              label={t('client.Email')}
                              sx={{ mb: 0 }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={`tenants.${index}.tenantSMS`}
                          defaultValue={false}
                          render={({ field }) => (
                            <>
                              <FormControlLabel
                                control={<Checkbox checked={field.value} sx={{ pt: 0, pb: 0 }} {...field} />}
                                label="SMS (Texto)"
                              />
                            </>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`tenants.${index}.tenantVoice`}
                          defaultValue={false}
                          render={({ field }) => (
                            <>
                              <FormControlLabel
                                control={<Checkbox checked={field.value} sx={{ pt: 0, pb: 0 }} {...field} />}
                                label="Téléphone"
                              />
                            </>
                          )}
                        />
                        <Controller
                          control={control}
                          name={`tenants.${index}.tenantEmail`}
                          defaultValue={false}
                          render={({ field }) => (
                            <>
                              <Controller
                                control={control}
                                name={`tenants.${index}.hasUnsubscribe`}
                                render={({ field: { value: hasUnsubscribe } }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={field.value}
                                        sx={{ pt: 0, pb: 0 }}
                                        {...field}
                                        disabled={hasUnsubscribe}
                                      />
                                    }
                                    label="Email"
                                  />
                                )}
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth
                      color="error"
                      variant="text"
                      onClick={() => {
                        deleteTenant(index)
                        removeTenantsRow(index)
                        onFieldsChange()
                      }}
                    >
                      {t('app.Delete')}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left-end' }}>
              <Button fullWidth variant="contained" onClick={() => handleCloseDialog()}>
                {t('app.Cancel')}
              </Button>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'right', justifyContent: 'right-end' }}>
              <Button fullWidth variant="contained" type="submit">
                {t('app.Save')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalCrudClient
